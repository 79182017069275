<template>
  <div id="Content">
    <div class="stepper-container">
      <div
        class="step-container"
        v-for="(step, index) in steps"
        :key="index"
        :class="{
          'non-linear': !nonLinear,
          laststep: lastStep === index
        }"
      >
        <!-- for step-container when we use non-linear methods -->
        <!-- active: activeStep === index + 1 -->

        <div class="step-name" v-if="!nonLinear" @click="goto(step)">
          <span class="step" v-if="!step.complete">
            {{ step.number }}
          </span>
          <span class="step" v-else>
            <font-awesome-icon icon="check"> </font-awesome-icon>
          </span>

          <span class="name"> {{ step.name }}</span>
        </div>
        <div class="step-name" @click="changeStep(step.number)" v-else>
          <span class="step" v-if="!step.complete">
            {{ step.number }}
          </span>
          <span class="step" v-else-if="step.complete && step.active">
            <font-awesome-icon icon="pen"> </font-awesome-icon>
          </span>
          <span class="step" v-else>
            <font-awesome-icon icon="check"> </font-awesome-icon>
          </span>

          <span class="name"> {{ step.name }}</span>
        </div>

        <div
          class="progress-divider"
          :class="{ incomplete: !steps[index + 1].complete }"
          v-if="lastStep != index"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faTimes, faCheck, faPen);

export default {
  name: "stepper",
  components: {
    FontAwesomeIcon
  },
  props: {
    steps: Array,
    nonLinear: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentStep: 1
    };
  },
  methods: {
    goto(step) {
      if (step.link) {
        this.$router.push(step.link);
      }
    },
    changeStep(step) {
      this.steps.forEach((step) => {
        step.active = false;
      });
      this.steps[step - 1].active = true;
      this.currentStep = step;
    }
  },
  computed: {
    lastStep() {
      return this.steps.length - 1;
    },
    activeStep() {
      return this.currentStep;
    }
  }
};
</script>

<style lang="less" scoped>
#Content {
  min-height: initial;
}
div.stepper-container {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0 30px;
  width: 90%;
  margin: auto;
}
div.step-container {
  display: flex;
  background: #222429;
  flex: 1;
  opacity: 0.5;

  &.non-linear {
    opacity: 1;
  }
  &.laststep {
    flex: 0;
  }
  &.active {
    opacity: 1;
  }
}

.step-name {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  .name {
    font-size: 14px;
    position: absolute;
    top: 40px;
    width: max-content;
  }
}
.step {
  background: var(--primary-blue);
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
}
.progress-divider {
  flex: 1 1 auto;
  height: 8px;
  background: #29acd9;
  // background: linear-gradient(90deg, #29acd9 50%, white 50%);
  align-self: center;
}
</style>
