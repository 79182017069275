<template>
  <modal title="Clone Event" width="640px" :active="active" @close="close">
    <div class="modal-input-event-title">
      <h4 class="cloning-event-name">Cloning {{ event.name }}</h4>
    </div>
    <FormulateForm
      name="cloneForm"
      v-model="formValues"
      @submit="handleSubmit"
      #default="{isLoading}"
      autocomplete="false"
    >
      <div class="modal-input-event-info">
        <div class="row modal-event-name-wrapper">
          <div class="col full">
            <FormulateInput
              type="text"
              name="name"
              label="New Event Name"
              placeholder="New Event Name"
              validation="required"
              autocomplete="false"
            />
          </div>
        </div>
        <div class="row">
          <div class="col half">
            <FormulateInput
              type="dateTime"
              name="startDate"
              label="Start Date"
              placeholder="MM/DD/YYYY ##:## PM"
              validation="required"
              autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
              type="dateTime"
              name="endDate"
              label="End Date"
              validation="required"
              placeholder="MM/DD/YYYY ##:## PM"
              :min-date="formValues.startDate"
              autocomplete="false"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <h4 class="col full">Optional Event Data</h4>
        <div
          v-for="(key, index) in Object.keys(dataSelectors)"
          class="col half"
          :key="index"
        >
          <diyobo-checkbox
            type="check"
            v-model="dataSelectors[key].enabled"
            :label="dataSelectors[key].label"
            :checked="dataSelectors[key].enabled"
          />
        </div>
      </div>
      <h5>
        <b>
          Make sure to confirm all dates and other important event details are
          correct before publishing.
        </b>
      </h5>
      <div class="modal-btn">
        <diyobo-button type="secondary" txt="Cancel" v-on:click="close" />
        <FormulateInput type="submit" name="Clone Event" :loading="isLoading" />
      </div>
    </FormulateForm>
  </modal>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import Modal from "@/components/Modal.vue";
import dayjs from "dayjs";

export default {
  name: "clone-event-modal",
  components: {
    DiyoboButton,
    DiyoboCheckbox,
    Modal
  },
  data() {
    return {
      active: false,
      event: {},
      dataSelectors: {
        // tiers: {
        //   label: "Ticket Tiers",
        //   enabled: true
        // },
        products: {
          label: "Products",
          enabled: true
        },
        promoCodes: {
          label: "Promo Codes",
          enabled: true
        },
        socialDiscounts: {
          label: "Social Discounts",
          enabled: true
        },
        emailAutomations: {
          label: "Email Automations",
          enabled: true
        },
        promoters: {
          label: "Promoters",
          enabled: true
        },
        customFields: {
          label: "Custom Fields",
          enabled: true
        }
      },
      formValues: {
        name: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: ""
      },
      formErrors: {
        name: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: ""
      }
    };
  },
  methods: {
    open(args = {}) {
      const event = args.event;

      this.event = event;

      let start = dayjs(+event.start);
      let end = dayjs(+event.end);

      this.formValues.startDate = dayjs(
        start < Date.now() ? Date.now() : start
      );

      if (end > Date.now()) {
        this.formValues.endDate = dayjs(end);
      }

      this.active = true;
    },
    close() {
      this.active = false;

      setTimeout(() => {
        this.event = {};

        Object.keys(this.dataSelectors).forEach(k => {
          this.dataSelectors[k].enabled = true;
        });
      }, 250);
    },
    async handleSubmit() {
      const additionalData = Object.entries(this.dataSelectors).reduce(
        (a, [k, v]) => {
          a[k] = v.enabled;
          return a;
        },
        {}
      );

      await this.$axios
        .post("/events/clone", {
          event: this.event.url,
          name: this.formValues.name,
          start: this.formValues.startDate,
          end: this.formValues.endDate,
          additionalData
        })
        .then(response => {
          if (response.status === 200) {
            let data = response.data;
            if (!data["failCheck"]) {
              this.$router.push(`/edit-event/${data.event_url}`);
              this.close();
            }
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
.cloning-event-name {
  margin: 0;
  margin-bottom: 16px;
}
#clone-event-modal {
  .modal-container {
    .modal-input-event-title {
      padding-left: 5%;
      display: flex;
      margin: 21px 0px;
      h4 {
        margin: 0px;
        margin-left: 10px;
      }
    }

    .modal-date-wrapper {
      .date {
        display: flex;
        position: relative;
        width: 100%;
        background-color: #181818;
        border: none;
        border-bottom: 1px solid #3b3b3b;
        border-radius: 8px;
        box-shadow: inset 0 2px 7px 2px #000000b9;

        .placeholder {
          position: absolute;
          top: 12px;
          left: 14px;
          color: #878787;
        }
        // end placeholder

        .icon-calender {
          position: absolute;
          top: 12px;
          right: 14px;
          color: #878787;
        }
        // end icon-calender

        .edit-icon {
          height: 22px;
          width: 22px;
        }

        input[type="date"],
        textarea {
          display: block;
          padding: 12px 0px;
          text-indent: 14px;
          width: 100%;
          font-family: inherit;
          font-size: 1em;
          color: #ffffff;
          background-color: #181818;
          border: none;
          border-bottom: 1px solid #3b3b3b;
          border-radius: 8px;
          box-shadow: inset 0 2px 7px 2px #000000b9;
          outline: none;
          -webkit-appearance: none;
          transition: 0.4s;

          &:focus {
            padding-left: 16px;
            padding-right: 12px;
            background-color: #1f1f1f;
          }
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
          opacity: 0.0000000001;
          position: relative;
          right: 2px;
          z-index: 777;
        }
        input[type="date"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          display: none;
        }
        input[type="date"]::-webkit-clear-button {
          display: none;
        }
      }
    }
    // end date

    // @media screen and (max-width: 1139px) {
    //     left: 18%;
    //     height: 450px;
    //     width: 60%;
    // }

    @media screen and (max-width: 759px) {
      top: 0;
      left: 11.7%;
      width: 70%;
    }

    @media screen and (max-width: 437px) {
      top: 0;
      height: 720px;
    }

    // end modal-header
    h5 {
      text-align: center;
    }

    .modal-input-event-info {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 98%;
      margin-top: 10px;
      //height: 83%;

      .modal-time-wrapper,
      .modal-date-wrapper {
        padding-left: 15px;
        width: 45%;

        @media screen and (max-width: 437px) {
          width: 92.5%;
        }
      }
      // end modal-timewrapper && modal-date-wrapper

      .modal-event-name-wrapper {
        padding-left: 15px;
        width: 92.5%;
      }
      // end modal-event-name-warpper
    }
    // end modal-input-event-info

    // end modal-btn
  }
}

.modal-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  &::v-deep button {
    margin: 0 8px;
  }

  &::v-deep .formulate-input {
    margin-bottom: 0;
    width: auto;
  }

  @media screen and (max-width: 437px) {
    padding-top: 20px;
  }
}
//end modal
</style>
