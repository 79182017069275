<template>
  <TTDialog
    :active="active"
    v-on:close="close"
    :width="700"
    title="Tickets statistic"
  >
    <div class="rates">
      <div class="rate">
        <span>Ticket type</span>
        <span class="center">Tickets Sold</span>
        <span class="center">Net revenue</span>
      </div>
      <div class="rate" v-for="ticket in tickets" v-if="ticket.count">
        <span>{{ ticket.highlight_text }}</span>
        <span class="center">{{ ticket.count }}</span>
        <span class="center">{{ formatMoney(ticket.total) }}</span>
      </div>
    </div>
    <div style="display: flex; justify-content: right;   padding-right: 20px; margin-top: 10px; margin-bottom: -20px">
      <diyobo-button
        type="secondary"
        txt="Close"
        @click="close"
      />
    </div>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import formatMoney from "@/helpers/money";


export default {
  name: "SoldTicketStatisticModal",
  components: {
    TTDialog,
    DiyoboButton,
  },
  props: {
    tickets: Object,
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    open(order) {
      this.order = order;

      setTimeout(() => {
        this.active = true;
      }, 50);
    },
    close() {
      this.active = false;
    },
  }
}
</script>

<style lang="less" scoped>
.detail {
  display: flex;
  flex-direction: column;
}

.rates {
  padding: 16px;
  background-color: var(--content-dark);
  border-radius: 8px;

  .rate {
    display: flex;

    span {
      width: 50%;
    }

    .center {
      display: flex;
      justify-content: center;
    }

    &:nth-child(1) {
      text-transform: uppercase;
    }

    &:nth-child(2) {
      margin-top: 8px;
    }
  }
}
</style>