<template>
  <div class="dashboard">
    <div id="Content">
      <div class="shadow"></div>
      <loader
        site="planners"
        :active="loading"
        message="Hang on tight. We're loading latest data."
      />
      <div class="content-inner" v-if="!loading">
        <clone-event-modal ref="cloneEventModal" />

        <div class="title-container">
          <div class="left-title-container">
            <h3>Previous Events</h3>
            <FormulateInput
              @click="displayAllPassEvents"
              type="button"
              label="Display All Past Events"
            />
          </div>
          <div class="right-title-container">
            <FormulateInput
              v-model="month"
              :options="monthList"
              type="select"
              placeholder="Month"
            />
            <FormulateInput
              v-model="year"
              :options="yearList"
              type="select"
              placeholder="Year"
            />
            <font-awesome-icon
              class="reset-icon fa-1x"
              icon="times"
              @click="resetFilter"
            />
          </div>
        </div>

        <div class="past-events">
          <past-event
            v-for="(item, index) in filteredPastEvents"
            :event="item"
            :key="index"
            :url="item.url"
            :name="item.name"
            :date="item.start"
            :created="item.created"
            v-on:openCloneModalPopUp="openCloneModal"
          />
          <div v-if="pastEvents.length === 0 && !loading">
            No Previous Events
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import PastEvent from "../../components/PastEvent.vue";
import LiveEvent from "../../components/LiveEvent.vue";
import Steppers from "../../components/Steppers.vue";
import CloneEventModal from "../../components/modals/CloneEventModal.vue";
import { convert } from "@/helpers/currencyConversion";
import Loader from "@/components/Loader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

library.add(faTimes);
const MONTHS = [
  { value: "Jan", label: "January" },
  { value: "Feb", label: "February" },
  { value: "Mar", label: "March" },
  { value: "Apr", label: "April" },
  { value: "May", label: "May" },
  { value: "Jun", label: "June" },
  { value: "Jul", label: "July" },
  { value: "Aug", label: "August" },
  { value: "Sep", label: "September" },
  { value: "Oct", label: "October" },
  { value: "Nov", label: "November" },
  { value: "Dec", label: "December" }
];

export default {
  name: "dashboard",
  components: {
    DiyoboButton,
    PastEvent,
    LiveEvent,
    CloneEventModal,
    Steppers,
    Loader,
    FontAwesomeIcon
  },
  head() {
    return {
      title: "Dashboard"
    };
  },
  data() {
    return {
      active: 0,
      loading: false,
      sold: 0,
      views: 0,
      ticketsAvailable: 0,
      sales: 0,
      uniqueBuyers: 0,
      refunds: 0,
      pastEvents: [],
      month: null,
      year: null,
      monthList: MONTHS,
      yearList: [],
      cloneEventModalUrl: "",
      cloneEventModalActive: false,
      bus: new Vue(),
      largest: 0,
      isDisplayAll: false
    };
  },
  computed: {
    filteredPastEvents() {
      if (this.year && this.month) {
        this.isDisplayAll = false;
        return this.pastEvents.filter(
          x =>
            (x.year === this.year && x.startMonth === this.month) ||
            (x.year === this.year && x.endMonth === this.month)
        );
      }
      if (this.month) {
        this.isDisplayAll = false;
        return this.pastEvents.filter(
          x => x.startMonth === this.month || x.endMonth === this.month
        );
      }
      if (this.year) {
        this.isDisplayAll = false;
        return this.pastEvents.filter(x => x.year === this.year);
      }
      if (this.isDisplayAll) {
        return this.pastEvents;
      }
      return [];
    },
    currentStoreCurrency() {
      return this.$store.state.currency;
    },
    cadOption() {
      return this.$store.state.cadCurrencyOption;
    },
    usdOption() {
      return this.$store.state.usdCurrencyOption;
    },

    activeBrand() {
      return this.$store.state.activeBrand;
    },
    activeEvents: {
      get: function() {
        return this.active;
      },
      set: function(val) {
        this.active = val;
      }
    }
  },
  methods: {
    displayAllPassEvents() {
      this.month = "";
      this.year = "";
      this.isDisplayAll = true;
    },
    resetFilter() {
      this.month = "";
      this.year = "";
      this.isDisplayAll = false;
    },
    formatMoney(price) {
      return convert(
        this.currentStoreCurrency.value,
        price,
        this.currentStoreCurrency.name
      );
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    openCloneModal(event) {
      this.$refs.cloneEventModal.open({ event });
    },
    toPastStats() {
      this.$el
        .querySelector(".past-events")
        .scrollIntoView({ behavior: "smooth" });
    },
    toBrandStats() {
      this.$router.push("/brand-stats");
    },

    loadDashboard() {
      let self = this;
      let chartData = [];
      let totalSales = 0;
      let activeBrand = this.$store.state.activeBrand.subdomain;

      this.$axios
        .post("/planners/dashboard", { url: activeBrand })
        .then(({ data }) => {
          data.past.forEach(element => {
            element.startInt = element.start.$date.$numberLong;
            element.start = new Date(parseInt(element.start.$date.$numberLong));
            element.created = new Date(
              parseInt(element.created.$date.$numberLong)
            );
          });
          self.pastEvents = data.past;
          data.yearList.forEach(e => {
            this.yearList.push({ value: e, label: e });
          });

          this.loading = false;
        })

        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },

    nFormatter(num, digits) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup
        .slice()
        .reverse()
        .find(function(item) {
          return num >= item.value;
        });
      return item
        ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
        : "0";
    }
  },

  mounted() {
    this.$store.commit("setTitle", "Current Live Events Dashboard");
    this.$store.dispatch(
      "updateHelpVideoSrc",
      "https://youtube.com/embed/IukiC6Kp7yE"
    );

    this.loading = true;
    this.loadDashboard();
  },
  beforeDestroy() {
    this.$store.dispatch("updateHelpVideoSrc", null);
  }
};
</script>

<style lang="less" scoped>
.dashboard {
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 8px 0;

    .left-title-container {
      display: flex;
      align-items: center;
      width: 49%;

      .formulate-input {
        margin: 0;
        text-align: -webkit-right;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      h3 {
        margin: 0;
        white-space: nowrap;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
    }
    .right-title-container {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 49%;

      .formulate-input {
        margin: 0;
      }
      .reset-icon {
        margin-right: 8px;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 950px) {
      .left-title-container,
      .right-title-container {
        width: 100%;
        margin: 8px 0;
      }
      .left-title-container {
        .formulate-input {
          text-align: -webkit-left;
        }
      }
    }
    @media screen and (max-width: 500px) {
      .left-title-container {
        flex-wrap: wrap;
        .formulate-input {
          flex-basis: auto;
          margin-top: 8px;
        }
      }
    }
  }
  .stats-outer {
    display: flex;
    flex-wrap: wrap-reverse;

    .left {
      .stats {
        width: 35%;
        margin-right: 23px;
        display: grid;
        flex-wrap: wrap;
        flex-basis: 100%;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        column-gap: 20px;

        .stat {
          padding: 10px 15px;
          padding-top: 0;
          min-width: 140px;
          min-height: 140px;
          background-image: var(--dashboard-tiles);
          border: 1px solid var(--dashboard-border);
          border-radius: 7px;
          box-shadow: var(--dashboard-shadow);
          display: flex;
          justify-content: center;
          align-items: center;

          .stat-info {
            text-align: center;

            div.number {
              margin: 0;
              padding: 0;
              font-size: 3em;
              font-weight: 900;

              color: var(--primary-blue);
            }

            h4 {
              margin: 0;
            }
          }
        }
        // end stat
      }

      // end stats
      .stats-inline {
        .stat {
          font-size: 1.9em;
          font-weight: 500;
          line-height: 100%;
          text-transform: uppercase;
          span {
            color: #4eb95e;
          }
        }
      }

      // end stats-inline
      .advisory {
        margin-right: 8px;
        padding-right: 15px;
        width: 160px;
        font-size: 0.8em;
        font-weight: 700;
        text-align: right;
        text-transform: uppercase;
        word-spacing: 1px;
        color: #7d7d7d;
      }
      // end advisory
    }

    // end left
    .right {
      width: calc(65% - 2px);
      height: inherit;

      .sales {
        padding: 20px 15px 5px;
        height: 298px;
        background-image: var(--dashboard-tiles);
        border: 1px solid var(--dashboard-border);
        border-radius: 7px;
        box-shadow: var(--dashboard-shadow);

        .sales-header {
          display: flex;
          justify-content: space-between;
          font-weight: 700;

          span:nth-child(2) {
            color: @colors[primary-green];
          }
        }

        // end sales-header
        .sales-graph {
          width: 100%;
          height: 90%;
          position: relative;
          overflow: hidden;

          canvas {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      // end sales
    }
    // end right
  }

  // end stats-outer
  @media screen and (max-width: 1170px) {
    .stats-outer {
      .left {
        width: 100%;

        .stats {
          width: 100%;

          .stat {
            min-width: initial;
            padding: 10px;
            min-height: initial;
          }
        }
      }

      .right {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;

    .left,
    .right {
      display: flex;
      width: 50%;

      button {
        margin: 20px 10px 0 10px;
      }
    }

    .left {
      justify-content: flex-end;
    }

    .right {
      justify-content: flex-start;
    }
  }

  .past-events {
    scroll-margin-top: 180px;
  }
}
// end dashboard
</style>
