<template>
  <div class="dashboard">
    <div id="Content">
      <div class="shadow"></div>
      <loader
        site="planners"
        :active="loading"
        message="Hang on tight. We're loading latest data."
      />
      <div class="content-inner" v-if="!loading">
        <clone-event-modal ref="cloneEventModal" />
        <!-- <hr class="divider" /> -->
        <!-- <h3>Your Statistics</h3> -->
        <!-- <div class="stats-outer">
          <div class="left">
            <div class="stats">
              <div class="stat">
                <div class="stat-info">
                  <div class="number">{{ activeEvents }}</div>
                  <h4>Active Events</h4>
                </div>
              </div>
              <div class="stat">
                <div class="stat-info">
                  <div class="number">{{ sold }}</div>
                  <h4>Tickets Sold</h4>
                </div>
              </div>
              <div class="stat">
                <div class="stat-info">
                  <div class="number">{{ views }}</div>
                  <h4>Event Views</h4>
                </div>
              </div>
              <div class="stat">
                <div class="stat-info">
                  <div class="number">{{ refunds }}</div>
                  <h4>Total Refunds</h4>
                </div>
              </div>
            </div>
           
          </div>
          <div class="right">
            <div class="sales">
              <div class="sales-header">
                <span>Active Events Revenue</span>
                <span>
                  {{ formatMoney(sales) }}
                  Total
                </span>
              </div>
              <div class="sales-graph" ref="salesGraph">
                <canvas ref="graph"></canvas>
              </div>
            </div>
          </div>
        </div> -->
        <!-- end stats-outer -->
        <!-- <div class="buttons">
          <div class="left">
            <diyobo-button
              type="primary"
              txt="Past Event Stats"
              @click="toPastStats"
            />
          </div>
          <div class="right">
            <diyobo-button
              type="primary"
              txt="All Event Stats"
              @click="toBrandStats"
            />
          </div>
        </div> -->
        <!-- <div class="divider"></div> -->
        <h3>Current Live Events</h3>
        <p class="note">Click on each event title to display the event options. It may take a few moments to load the data entirely.</p>
        <div class="live-events">
          <new-live-event
            v-for="(item, index) in currentEvents"
            :event="item"
            :key="index"
            :url="item.url"
            :name="item.name"
            :date="item.start"
            :created="item.created"
            :sold="item.registration_count ? item.registration_count  : item.tickets_sold"
            :revenue="item.tickets_revenue"
            v-on:openCloneModalPopUp="openCloneModal"
          />
          <div v-if="currentEvents.length === 0 && !loading">
            No Current Live Events
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.dashboard {
  .stats-outer {
    display: flex;
    flex-wrap: wrap-reverse;

    .left {
      .stats {
        width: 35%;
        margin-right: 23px;
        display: grid;
        flex-wrap: wrap;
        flex-basis: 100%;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        column-gap: 20px;

        .stat {
          padding: 10px 15px;
          padding-top: 0;
          min-width: 140px;
          min-height: 140px;
          background-image: var(--dashboard-tiles);
          border: 1px solid var(--dashboard-border);
          border-radius: 7px;
          box-shadow: var(--dashboard-shadow);
          display: flex;
          justify-content: center;
          align-items: center;

          .stat-info {
            text-align: center;

            div.number {
              margin: 0;
              padding: 0;
              font-size: 3em;
              font-weight: 900;

              color: var(--primary-blue);
            }

            h4 {
              margin: 0;
            }
          }
        }
        // end stat
      }

      // end stats
      .stats-inline {
        .stat {
          font-size: 1.9em;
          font-weight: 500;
          line-height: 100%;
          text-transform: uppercase;
          span {
            color: #4eb95e;
          }
        }
      }

      // end stats-inline
      .advisory {
        margin-right: 8px;
        padding-right: 15px;
        width: 160px;
        font-size: 0.8em;
        font-weight: 700;
        text-align: right;
        text-transform: uppercase;
        word-spacing: 1px;
        color: #7d7d7d;
      }
      // end advisory
    }

    // end left
    .right {
      width: calc(65% - 2px);
      height: inherit;

      .sales {
        padding: 20px 15px 5px;
        height: 298px;
        background-image: var(--dashboard-tiles);
        border: 1px solid var(--dashboard-border);
        border-radius: 7px;
        box-shadow: var(--dashboard-shadow);

        .sales-header {
          display: flex;
          justify-content: space-between;
          font-weight: 700;

          span:nth-child(2) {
            color: @colors[primary-green];
          }
        }

        // end sales-header
        .sales-graph {
          width: 100%;
          height: 90%;
          position: relative;
          overflow: hidden;

          canvas {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      // end sales
    }
    // end right
  }

  // end stats-outer
  @media screen and (max-width: 1170px) {
    .stats-outer {
      .left {
        width: 100%;

        .stats {
          width: 100%;

          .stat {
            min-width: initial;
            padding: 10px;
            min-height: initial;
          }
        }
      }

      .right {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;

    .left,
    .right {
      display: flex;
      width: 50%;

      button {
        margin: 20px 10px 0 10px;
      }
    }

    .left {
      justify-content: flex-end;
    }

    .right {
      justify-content: flex-start;
    }
  }

  .past-events {
    scroll-margin-top: 180px;
  }
}
.note {
  margin-top: 0px;
  margin-bottom: 20px;
}
// end dashboard
</style>

<script>
import Vue from "vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import PastEvent from "../../components/PastEvent.vue";
import LiveEvent from "../../components/LiveEvent.vue";
import NewLiveEvent from "../../components/NewLiveEvent.vue";
import Steppers from "../../components/Steppers.vue";
import CloneEventModal from "../../components/modals/CloneEventModal.vue";
import { convert } from "@/helpers/currencyConversion";
import Loader from "@/components/Loader.vue";
import { nFormatter } from "../../helpers/numbers";
import dateFormat from "dateformat";

export default {
  name: "dashboard",
  components: {
    DiyoboButton,
    PastEvent,
    LiveEvent,
    CloneEventModal,
    Steppers,
    Loader,
    NewLiveEvent
  },
  head() {
    return {
      title: "Dashboard"
    };
  },
  data() {
    return {
      active: 0,
      loading: false,
      sold: 0,
      views: 0,
      ticketsAvailable: 0,
      sales: 0,
      uniqueBuyers: 0,
      refunds: 0,
      currentEvents: [],
      cloneEventModalUrl: "",
      cloneEventModalActive: false,
      bus: new Vue(),
      largest: 0
    };
  },
  computed: {
    currentStoreCurrency() {
      return this.$store.state.currency;
    },
    cadOption() {
      return this.$store.state.cadCurrencyOption;
    },
    usdOption() {
      return this.$store.state.usdCurrencyOption;
    },

    activeBrand() {
      return this.$store.state.activeBrand;
    },
    activeEvents: {
      get: function() {
        return this.active;
      },
      set: function(val) {
        this.active = val;
      }
    }
  },
  methods: {
    formatMoney(price) {
      return convert(
        this.currentStoreCurrency.value,
        price,
        this.currentStoreCurrency.name
      );
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    openCloneModal(event) {
      this.$refs.cloneEventModal.open({ event });
    },
    toPastStats() {
      this.$el
        .querySelector(".past-events")
        .scrollIntoView({ behavior: "smooth" });
    },
    toBrandStats() {
      this.$router.push("/brand-stats");
    },

    loadDashboard() {
      let self = this;
      let chartData = [];
      let totalSales = 0;
      let activeBrand = this.$store.state.activeBrand.subdomain;
      this.$axios
        .post("/planners/dashboard", { url: activeBrand })
        .then(({ data }) => {
          data.current.forEach(element => {
            element.startInt = element.start.$date.$numberLong;
            element.start = new Date(parseInt(element.start.$date.$numberLong));
            element.created = new Date(
              parseInt(element.created.$date.$numberLong)
            );
            element.tickets_sold =
              element.tickets_sold == "0.00"
                ? "0"
                : nFormatter(element.tickets_sold, 0);
            element.tickets_revenue = nFormatter(element.tickets_revenue, 2);
            if(element.registration_count) {
              element.registration_count = nFormatter(element.registration_count)
            }

            element.cloneable = !element.tiers.some(
              t => t.type !== "General Tier"
            );
          });
          self.currentEvents = data.current;
          this.loading = false;
        })

        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    }
  },

  mounted() {
    this.$store.commit("setTitle", "Current Live Events Dashboard");
    this.$store.dispatch(
      "updateHelpVideoSrc",
      "https://youtube.com/embed/IukiC6Kp7yE"
    );

    this.loading = true;
    this.loadDashboard();
  },
  beforeDestroy() {
    this.$store.dispatch("updateHelpVideoSrc", null);
  }
};
</script>
